var cart = document.currentScript.getAttribute('cart').replace(/[\r\n]/, '').split('|');
var customer,product,category,items;
if(document.currentScript.getAttribute('customer')){
    customer = document.currentScript.getAttribute('customer').replace(/[\r\n]/, '').split('|');
}
if(document.currentScript.getAttribute('product')){
    product = document.currentScript.getAttribute('product').replace(/[\r\n]/, '').split('|');
}
if(document.currentScript.getAttribute('collection')){
    category = document.currentScript.getAttribute('collection').replace(/[\r\n]/, '').split('|');
}
if(document.currentScript.getAttribute('items')){
    items = document.currentScript.getAttribute('items').replace(/[\r\n]/, '').split('|');
}
var pageName = document.currentScript.getAttribute('page');

var logged_in = 'non';
if (customer) {
    logged_in = 'oui';
}

if(pageName.includes('product') ){
    if (logged_in == 'oui') {
        dataLayer = [{
            'connexion.compte' : logged_in,
            'id.client' :customer[0],
            'nom.client' :customer[1],
            'prenom.client' : customer[2],
            'email.client' : customer[3],
            'montant.panier' :cart[0],
            'nombre.article.panier' :cart[1],
            'categorie.produit' : product[2],
            'nom.produit' :product[0],
            'id.produit' : product[1]
        }];
    }else{
        dataLayer = [{
            'connexion.compte' : logged_in,
            'montant.panier' :cart[0],
            'nombre.article.panier' :cart[1],
            'categorie.produit' : product[2],
            'nom.produit' :product[0],
            'id.produit' : product[1]
        }];
    }
}else if(pageName.includes('collection') ){
    if (logged_in == 'oui') {
        dataLayer = [{
            'connexion.compte' : logged_in,
            'id.client' :customer[0],
            'nom.client' :customer[1],
            'prenom.client' : customer[2],
            'email.client' : customer[3],
            'montant.panier' :cart[0],
            'nombre.article.panier' :cart[1],
            'liste.produits' : category
        }];
    }else{
        dataLayer = [{
            'connexion.compte' : logged_in,
            'montant.panier' :cart[0],
            'nombre.article.panier' :cart[1],
            'liste.produits' : category
        }];
    }
}else if(pageName.includes('cart') ){
    var jsonArr = [];
    for (var i = 0; i < items.length -1; i+=3) {
        jsonArr.push({
            id: items[i],
            price: items[i+1],
            quantity: items[i+2],
        });
    }
    if (logged_in == 'oui') {
        dataLayer = [{
            'connexion.compte' : logged_in,
            'id.client' :customer[0],
            'nom.client' :customer[1],
            'prenom.client' : customer[2],
            'email.client' : customer[3],
            'montant.panier' :cart[0],
            'nombre.article.panier' :cart[1],
            'contenu.panie' : jsonArr
        }];
    }else{
        dataLayer = [{
            'connexion.compte' : logged_in,
            'montant.panier' :cart[0],
            'nombre.article.panier' :cart[1],
            'contenu.panie' : jsonArr

        }];
    }
}else{
    if (logged_in == 'oui') {
        dataLayer = [{
            'connexion.compte' : logged_in,
            'id.client' :customer[0],
            'nom.client' :customer[1],
            'prenom.client' : customer[2],
            'email.client' : customer[3],
            'montant.panier' :cart[0],
            'nombre.article.panier' :cart[1]
            
        }];
    }else{
        dataLayer = [{
            'connexion.compte' : logged_in,
            'montant.panier' :cart[0],
            'nombre.article.panier' :cart[1]
        }];
    }
}
